.image-select {
  border: solid 2px whitesmoke;
  border-radius: 5%;
  position: relative;
  cursor: pointer;
  padding: 5px;
}

.image-select:hover {
  border: solid 2px #4da6ff;
  border-radius: 5%;
  cursor: pointer;
}

.image-selected {
  border: solid 2px #4da6ff;
  border-radius: 5%;
  position: relative;
  cursor: pointer;
  padding: 5px;
}

.check-icon {
  position: absolute;
  top: -1px;
  right: -1px;
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 3px white;
  background: #4da6ff;
  text-align: center;
  color: white;
}

.hide {
  display: none;
}

@media (min-width: 992px) { /* lg breakpoint */
  .custom-modal {
    min-width: 750px; /* ค่าที่คุณต้องการ */
  }
}