.image {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.image:hover {
  border: solid 1px black;
  border-radius: 10px;
}
